<template>
  <div class="modal" :class="{ 'is-active': diplayConfirmAbandon }" scroll="keep">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="modal-card-body">
        <h1 class="title mt-2 has-text-weight-light" v-translate>Abandonar pedido</h1>
        <div class="my-4 is-size-5 has-text-weight-light" v-translate>
          ¿Estás seguro que deseas abandonar el pedido en curso? Es posible que tu
          selección deje de estar disponible
        </div>
        <div class="my-2">
          <b-button
            class="is-fullwidth has-text-grey"
            @click="hideConfirmAbandon"
            v-translate
          >
            No, deseo continuar con el pedido
          </b-button>
          <b-button
            class="mt-2 is-fullwidth"
            type="is-info"
            @click="abandon"
            v-translate
          >
            Sí, quiero realizar un nuevo pedido
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex"

export default {
  name: "AbandonOrderDialog",
  props: ["order", "goToWeb", "goToWebClicentradas"],
  data() {
    return {
      diplayConfirmAbandon: false,
      abandoned: false,
    }
  },
  methods: {
    ...mapActions({
      multipassAbandonOrder: "multipassOrder/abandon",
    }),
    abandon() {
      this.multipassAbandonOrder(this.order.lookup_ref)
        .catch(() => {})
        .finally(() => {
          this.abandoned = true
          if (this.goToWeb == true) {
            if (this.$theme().name) {
              window.location.href = this.$theme().href
            }
            window.location.href = this.$theme().href
          } else {
            this.$router.replace({
              name: "multipass-detail",
              params: { lookupRef: this.order.multipass_config.lookup_ref },
              query: this.$defaultQuery(),
            })
          }
        })
    },
    hideConfirmAbandon() {
      this.goToWeb = false
      this.diplayConfirmAbandon = false
      this.$emit("hideConfirmAbandon")
    },
    confirmAbandon() {
      this.goToWeb = false
      this.diplayConfirmAbandon = true
    },
  },
}
</script>
