<template>
  <section class="contact-data-form">
    <h3 v-translate>Datos de contacto</h3>
    <form ref="contactDataForm" autocomplete="on">
      <p class="has-errors-msg" v-if="hasErrors" v-translate>
        Por favor, corrige los siguientes errores.
      </p>
      <div class="card column is-12">
        <div class="column columns is-multiline">
          <div class="column is-12">
            <FieldInput
              ref="email"
              :errors="errors.email"
              :focus="true"
              name="email"
              autocomplete="email"
              v-model="formData.email"
              :required="true"
              type="email"
              :label="strings['Email']"
            />
          </div>
          <div class="column is-5">
            <FieldInput
              ref="name"
              :errors="errors.name"
              name="name"
              autocomplete="given-name"
              v-model="formData.name"
              :required="true"
              :label="strings['Nombre']"
            />
          </div>
          <div class="column is-7">
            <FieldInput
              ref="surname"
              :errors="errors.surname"
              autocomplete="family-name"
              name="surname"
              v-model="formData.surname"
              :required="true"
              :label="strings['Apellidos']"
            />
          </div>
          <div class="column is-7">
            <FieldInput
              ref="phone"
              :errors="errors.phone"
              autocomplete="tel"
              name="phone"
              type="tel"
              v-model="formData.phone"
              :required="true"
              :label="strings['Teléfono']"
            />
          </div>
          <div class="column is-5">
            <FieldInput
              ref="postal_code"
              :errors="errors.postal_code"
              autocomplete="postal-code"
              name="postal_code"
              type="text"
              pattern="[0-9]*"
              v-model="formData.postal_code"
              :required="true"
              :label="strings['Código Postal']"
            />
          </div>
        </div>
      </div>

      <section class="participants-form mt-4 mb-1" v-if="isIdentified">
        <h3 v-translate>Asistentes</h3>
        <div v-for="(virtualSeat, key, $index) in order.virtual_seats" :key="key">
          <MultipassOrderAttendeeForm
            :order="order"
            :virtualSeat="virtualSeat"
            :index="$index"
            :attendee.sync="attendees[$index]"
            :subdivisions="subdivisions"
            :groupedByIndex="order.multipass_config.seat_mode == 'free_will'"
            :errors="{
              name: errors[`attendees.${$index}.name`],
              surname: errors[`attendees.${$index}.surname`],
              city: errors[`attendees.${$index}.city`],
              province: errors[`attendees.${$index}.province`],
              phone: errors[`attendees.${$index}.phone`],
              identity_card: errors[`attendees.${$index}.identity_card`],
              date_of_birth: errors[`attendees.${$index}.date_of_birth`],
              email: errors[`attendees.${$index}.email`],
              postal_code: errors[`attendees.${$index}.postal_code`],
              company_name: errors[`attendees.${$index}.company_name`],
              fiscal_address: errors[`attendees.${$index}.fiscal_address`],
              fiscal_identification_code:
                errors[`attendees.${$index}.fiscal_identification_code`],
              subscriber_number: errors[`attendees.${$index}.subscriber_number`],
            }"
            v-if="subdivisions.length > 0"
          ></MultipassOrderAttendeeForm>
        </div>
      </section>
      <div class="column is-12 is-size-7 has-text-grey">
        *
        <translate>Campos requeridos</translate>
      </div>
      <div class="column is-12">
        <FieldCheckbox
          v-if="order.any_event_has_specific_conditions"
          ref="order_specific_conditions_status"
          :errors="errors.specific_conditions_status"
          name="order_specific_conditions_status"
          true-value="given"
          false-value="withdrawn"
          v-model="orderSpecificConditionsStatus"
          @input="emitData"
          disabled
        >
          <div @click="$emit('displayConditions')">
            {{ order.event_conditions_texts.checkbox }}
          </div>
        </FieldCheckbox>
      </div>
      <div class="column is-12">
        <FieldCheckbox
          ref="gdpr_consent_status"
          :errors="errors.gdpr_consent_status"
          name="gdpr_consent_status"
          true-value="given"
          false-value="withdrawn"
          v-model="gdprConsentStatus"
          @input="emitData"
        >
          <div>
            <translate>He leído y acepto las</translate>
            &nbsp;
            <a
              href="https://entradas.ataquilla.com/ventaentradas/es/content/3-condiciones-generales-de-compra"
              target="_blank"
            >
              <translate>Condiciones generales de compra</translate>
            </a>
          </div>
        </FieldCheckbox>
        <div class="mb-2">
          <span>
            <span v-translate class="has-text-weight-bold">
              Responsable del Tratamiento:
            </span>
            <translate>Fundación Galicia Obra Social.</translate>
            <span v-translate class="has-text-weight-bold">
              Finalidad del tratamiento:
            </span>
            <translate>compra y adquisición de entradas.</translate>
            <span v-translate class="has-text-weight-bold">
              Legitimación del tratamiento:
            </span>
            <translate>ejecución de un contrato/acuerdo.</translate>
            <span v-translate class="has-text-weight-bold">Destinatarios:</span>
            <translate>
              no se cederán datos salvo los necesario para la prestación del servicio o
              en caso de obligación legal.
            </translate>
            <span v-translate class="has-text-weight-bold">Derechos:</span>
            <translate>
              acceder, rectificar y suprimir los datos, así como otros derechos, como se
              explica en la
            </translate>
            <a
              href="https://entradas.ataquilla.com/ventaentradas/es/content/5-politica-de-privacidad"
              target="_blank"
              class="privacy-policy"
              v-translate
            >
              política de privacidad
            </a>
            .
          </span>
        </div>
        <div v-if="promoterConsentMap != {}">
          <FieldCheckbox
            v-for="promoter of order.promoters"
            :key="promoter.id"
            ref="promoterConsentMap[promoter.id]"
            name="promoterConsentMap"
            true-value="given"
            false-value="withdrawn"
            v-model="promoterConsentMap[promoter.id]"
            @input="emitData"
          >
            <div>
              <translate>
                Deseo recibir información sobre los productos y servicios ofrecidos por
              </translate>
              &nbsp;
              <span class="has-text-weight-bold">
                {{ promoter.name }}
              </span>
              &nbsp;
              <translate>que puedan ser de mi interés.</translate>
            </div>
          </FieldCheckbox>
        </div>
      </div>
    </form>
    <b-modal :active.sync="conditionsModalVisible" scroll="keep" :can-cancel="true">
      <ConditionsModal></ConditionsModal>
    </b-modal>
    <b-modal :active.sync="policyModalVisible" scroll="keep" :can-cancel="true">
      <PolicyModal></PolicyModal>
    </b-modal>
  </section>
</template>
<script>
import _ from "lodash"

import FormMixin from "@/mixins/FormMixin.ts"

import MultipassOrderAttendeeForm from "@/components/multipass/MultipassOrderAttendeeForm"
import ConditionsModal from "@/components/modals/ConditionsModal"
import PolicyModal from "@/components/modals/PolicyModal"

export default {
  name: "MultipassContactDataForm",
  mixins: [FormMixin],
  props: {
    order: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    ConditionsModal,
    PolicyModal,
    MultipassOrderAttendeeForm,
  },
  mounted() {
    this.$api.subdivisions().then((response) => {
      this.subdivisions = response.results.sort((a, b) => {
        return String(a.name).localeCompare(String(b.name))
      })
    })

    this.order.promoters.forEach((p) => {
      this.promoterConsentMap[p.id] = false
    })
    this.fillForm(this.order)
  },
  data() {
    return {
      conditionsModalVisible: false,
      policyModalVisible: false,
      gdprConsentStatus: "withdrawn",
      orderSpecificConditionsStatus: "withdrawn",
      promoterConsentMap: {}, // "withdrawn" (false) or "given" (true)
      attendees: [],
      formData: {
        email: null,
        name: null,
        surname: null,
        phone: null,
        postal_code: null,
      },
      subdivisions: [],
    }
  },
  computed: {
    hasErrors() {
      return _.size(this.errors)
    },
    strings() {
      return {
        Email: this.$gettext("Email"),
        Nombre: this.$gettext("Nombre"),
        Apellidos: this.$gettext("Apellidos"),
        Teléfono: this.$gettext("Teléfono"),
        "Código Postal": this.$gettext("Código Postal"),
      }
    },
    numTickets() {
      return this.tickets.length
    },
    tickets() {
      return _.filter(
        this.order?.tickets,
        (ticket) => ticket.seat.zone_type != "row_zero"
      )
    },
    isIdentified() {
      if (this.order && this.order.attendee_form_config) {
        return Object.keys(this.order.attendee_form_config).length > 0
      }
      return false
    },
  },
  methods: {
    showConditionsModal(e) {
      e.preventDefault()
      e.stopPropagation()
      this.conditionsModalVisible = true
    },
    showPolicyModal(e) {
      e.preventDefault()
      e.stopPropagation()
      this.policyModalVisible = true
    },
    getCleanedData() {
      // Append theme at the end of the URL if using any
      const hasTheme = this.$route && this.$route.query && "t" in this.$route.query
      const urlEnd = hasTheme ? `?t=${this.$route.query.t}` : ""
      let cleanedData = _.merge(this.cleanedData, {
        gdpr_consent_status: this.gdprConsentStatus,
        order_specific_conditions_status: this.orderSpecificConditionsStatus,
        url_ok: `${window.location.origin}/#/multipass-order/${this.order.lookup_ref}/ok${urlEnd}`,
        url_error: `${window.location.origin}/#/multipass-order/${this.order.lookup_ref}/error${urlEnd}`,
        attendees: this.attendees,
      })

      return cleanedData
    },
    emitData() {
      let data = this.getCleanedData()
      this.$emit("update:data", data)
    },
  },
  watch: {
    order: {
      handler() {
        this.fillForm(this.order)
      },
      deep: true,
    },
    formData: {
      handler() {
        this.emitData()
      },
      deep: true,
    },
    attendees: {
      handler() {
        this.emitData()
      },
      deep: true,
    },
    errors(value) {
      if (!_.size(value)) return
      let errorKeys = _.intersection(_.keys(this.$refs), _.keys(value))
      let fieldName = _.first(errorKeys)
      if (this.$refs[fieldName]) {
        this.$refs[fieldName].setFocus()
      }
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
p.has-errors-msg
  color: $danger
  background: rgba($danger, 0.1)
  font-size: .875rem
  padding: 1em .75em

.is-danger .card .card-content
  border: 1px solid $danger

.participants-form
      order: 0
      width: 100%
</style>
