import Vue from "vue"
import Component from "vue-class-component"

@Component
export default class OrderMixin extends Vue {
  order: any = null

  beforeMount() {
    if (!this.$route.params.lookup_ref)
      this.$router.replace({ name: "404", query: this.$defaultQuery() })
    this.getMultipassOrder()
  }

  get isLoading() {
    return !this.order
  }

  getMultipassOrder() {
    this.$api
      .getMultipassOrder(this.$route.params.lookup_ref)
      .then((response: any) => {
        this.order = response
      })
      .catch(() => {
        this.order = {}
      })
  }

  newOrder() {
    this.$router.push({
      name: "multipass-detail",
      params: { lookupRef: this.order.multipass_config.lookup_ref },
      query: this.$defaultQuery(),
    })
  }
}
