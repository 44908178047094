<template>
  <div
    class="modal"
    :class="{ 'is-active': showModal && hasConditions }"
    @click="$emit('close')"
    scroll="keep"
  >
    <div class="modal-background"></div>
    <div class="modal-content conditions-modal">
      <div class="modal-card-body">
        <div v-for="event in allEventConditions" :key="event.id" class="mb-2">
          <div class="is-size-5 has-text-weight-bold has-text-pure-black">
            <span>{{ event.specific_conditions_title }}</span>
          </div>
          <div
            class="is-size-6 has-text-weight-light has-text-pure-black pre-formatted"
          >
            {{ event.specific_conditions_text }}
          </div>
        </div>

        <div class="my-2">
          <b-button
            v-if="!isReservation"
            class="is-fullwidth has-text-grey"
            @click="closeAndAbandonModal"
          >
            <translate>Abandonar el pedido</translate>
          </b-button>
          <b-button class="mt-2 is-fullwidth" type="is-info" @click="agreeConditions">
            {{ order.event_conditions_texts.button }}
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipassConditionsDialog",
  props: ["order"],
  computed: {
    hasConditions() {
      return this.order.any_event_has_specific_conditions
    },
    allEventConditions() {
      const eventMap = {}
      this.order.children.forEach((child) => {
        eventMap[child.event.id] = child.event
      })
      const uniqueEvents = Object.values(eventMap)
      return uniqueEvents
    },
    isReservation() {
      return this.order.source === "reservation"
    },
  },
  data() {
    return {
      cancelConditions: true,
      showModal: true,
    }
  },
  methods: {
    closeAndAbandonModal() {
      this.showModal = false
      this.$emit("abandon")
    },
    agreeConditions() {
      this.showModal = false
      this.$emit("agree")
    },
  },
}
</script>

<style lang="sass" scoped>
@supports (max-height: 100svh)
  .conditions-modal
    max-height: 100svh !important

@supports not (max-height: 100svh)
  .conditions-modal
    padding-bottom: 60px !important

.title
  font-size: 1.5rem

.has-text-pure-black
  color: black
</style>
