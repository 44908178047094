var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card mt-2"},[_c('div',{staticClass:"card-content"},[_c('div',[_c('div',{staticClass:"attendee-summary"},[_c('div',[_c('span',{staticClass:"has-text-weight-bold mr-2"},[_vm._v(" "+_vm._s(_vm.virtualSeat.label)+" ")]),(!_vm.groupedByIndex && _vm.virtualSeat.concession)?_c('span',[_vm._v(" "+_vm._s(_vm.virtualSeat.concession)+" ")]):_vm._e()]),(!_vm.groupedByIndex)?_c('div',[_c('span',[_c('translate',[_vm._v("Zona:")]),_vm._v(" "+_vm._s(_vm.virtualSeat.zone)+" ")],1)]):_vm._e()]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-5"},[_c('label',{staticClass:"labels"},[_c('translate',[_vm._v("Nombre")]),_vm._v(" * ")],1),_c('FieldInput',{attrs:{"name":"name","required":true,"errors":_vm.attendeeErrors.name},model:{value:(_vm.formDataParticipant.name),callback:function ($$v) {_vm.$set(_vm.formDataParticipant, "name", $$v)},expression:"formDataParticipant.name"}})],1),_c('div',{staticClass:"column is-7"},[_c('label',{staticClass:"labels"},[_c('translate',[_vm._v("Apellidos")]),_vm._v(" * ")],1),_c('FieldInput',{attrs:{"name":"surname","required":true,"errors":_vm.attendeeErrors.surname},model:{value:(_vm.formDataParticipant.surname),callback:function ($$v) {_vm.$set(_vm.formDataParticipant, "surname", $$v)},expression:"formDataParticipant.surname"}})],1)]),_vm._l((_vm.order.attendee_form_config),function(item,key,$index){return _c('div',{key:$index,staticClass:"columns is-multiline"},[(item.status == 'mandatory' || item.status == 'optional')?_c('div',{staticClass:"column is-12"},[_c('label',{staticClass:"labels"},[_vm._v(" "+_vm._s(item.label)+" "),(item.status == 'mandatory')?_c('span',[_vm._v("*")]):_vm._e()]),(item.field != 'province')?_c('FieldInput',{attrs:{"name":item.field,"required":item.status == 'mandatory',"type":item.field == 'phone'
                ? 'tel'
                : item.field == 'date_of_birth'
                ? 'date'
                : item.field == 'email'
                ? 'email'
                : 'text',"errors":_vm.attendeeErrors[item.field]},model:{value:(_vm.formDataParticipant[item.field]),callback:function ($$v) {_vm.$set(_vm.formDataParticipant, item.field, $$v)},expression:"formDataParticipant[item.field]"}}):[_c('b-select',{staticClass:"field",class:{
                'is-required': item.status == 'mandatory',
                'is-danger':
                  _vm.attendeeErrors[item.field] && _vm.attendeeErrors[item.field].length > 0,
              },attrs:{"required":item.status == 'mandatory',"errors":_vm.attendeeErrors[item.field],"name":item.field},model:{value:(_vm.formDataParticipant[item.field]),callback:function ($$v) {_vm.$set(_vm.formDataParticipant, item.field, $$v)},expression:"formDataParticipant[item.field]"}},_vm._l((_vm.subdivisions),function(province){return _c('option',{key:province.id},[_vm._v(" "+_vm._s(province.name)+" ")])}),0),_c('p',{key:$index,staticClass:"help is-danger"},[_vm._v(" "+_vm._s(_vm.attendeeErrors[item.field])+" ")])]],2):_vm._e()])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }