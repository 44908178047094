<template>
  <PageDefault :isLoading="isLoading" :order="order">
    <template v-slot:navbar-start>
      <div class="navbar-item">
        <a
          class="button is-link has-text-white bg-none"
          @click="goBack"
          v-if="order && !isReservation"
        >
          <b-icon pack="fas" icon="arrow-left"></b-icon>
        </a>
      </div>
    </template>
    <template v-slot:navbar-brand>
      <div class="navbar-item">
        <a @click="goBackWeb">
          <img :src="$theme().image" :alt="$theme().name" />
        </a>
      </div>
    </template>

    <template v-slot:navbar-end>
      <Timer
        v-if="!ffTimerV2Enabled && order && order.status != 'reserved'"
        class="navbar-item"
        style="margin-bottom: 0.25rem"
        :order="order"
        :isMultipassOrder="true"
      />
      <TimerV2
        v-if="ffTimerV2Enabled && order && order.status != 'reserved'"
        class="navbar-item"
        style="margin-bottom: 0.25rem"
        :order="order"
        :isMultipassOrder="true"
      />
    </template>
    <template v-slot:content>
      <MultipassAbandonOrderDialog
        v-if="order && !isReservation"
        ref="orderDialogRef"
        :order="order"
        :goToWeb="goToWeb"
        @hideConfirmAbandon="displayConditionsDialog"
      />
      <MultipassConditionsDialog
        ref="conditionsDialog"
        :order="order"
        @abandon="conditionsDialogAbandon"
        @agree="agreeConditions"
      />
      <div class="annotation">
        <translate>
          Dispones de un tiempo limitado para realizar tu compra. Tras este tiempo se
          liberará tu reserva automáticamente.
        </translate>
        <template v-if="order.status === 'reserved'">
          <p class="mt-1" v-translate>Reservado hasta:</p>
          <p>{{ order.expiration | formatDateTimeHuman }}</p>
        </template>
      </div>
      <MultipassOrderSummary class="order-summary" :order="order" />
      <MultipassContactDataForm
        class="contact-data-form mt-4"
        :order="order"
        :errors="errors"
        :data.sync="formData"
        ref="contactDataForm"
        @displayConditions="displayConditionsDialog"
      />
      <PaymentForm ref="paymentForm" :order="order" />
      <PaymentInfo class="payment-info mx-1" v-if="!isFree" />
      <section class="block mx-1 mb-3">
        <button
          @click="confirmOrder"
          data-cy="button-confirm-order"
          class="button button-2406 fullwidth-mobile is-info"
          type="submit"
          v-if="!isFree"
          :disabled="isConfirmingOrder"
        >
          <translate>Pagar ahora</translate>
        </button>
        <div style="height: 75px"></div>
      </section>
    </template>
  </PageDefault>
</template>

<script>
import MultipassOrderMixin from "@/mixins/MultipassOrderMixin"

import MultipassAbandonOrderDialog from "@/components/dialogs/MultipassAbandonOrderDialog"
import Timer from "@/components/Timer"
import TimerV2 from "@/components/TimerV2"

import MultipassContactDataForm from "@/components/multipass/MultipassContactDataForm"
import MultipassOrderSummary from "@/components/multipass/MultipassOrderSummary"
import PaymentInfo from "@/components/sections/PaymentInfo"
import PaymentForm from "@/components/sections/PaymentForm"
import filters from "@/filters"
import MultipassConditionsDialog from "@/components/dialogs/MultipassConditionsDialog.vue"

export default {
  name: "MultipassOrderConfirm",
  filters: filters,
  components: {
    MultipassAbandonOrderDialog,
    Timer,
    TimerV2,
    MultipassConditionsDialog,
    MultipassContactDataForm,
    MultipassOrderSummary,
    PaymentInfo,
    PaymentForm,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFree && to.name == "multipass-order-success") {
      return next()
    }
    if (to.name == "multipass-order-expired") {
      return next()
    }
    if (this.$refs.orderDialogRef && !this.$refs.orderDialogRef.abandoned) {
      this.$refs.orderDialogRef.confirmAbandon()
      return next(false)
    }
    return next()
  },
  mixins: [MultipassOrderMixin],
  computed: {
    isFree() {
      if (!this.order.tickets) {
        return false
      }
      return this.order.tickets.every((item) => item.is_free)
    },
    isReservation() {
      if (!this.order) {
        return false
      }
      return this.order.source == "reservation"
    },
    ffTimerV2Enabled() {
      return this.$env && this.$env.ff_timer_v2 === true
    },
  },
  data() {
    return {
      errors: {},
      formData: {},
      isConfirmingOrder: false,
      isConfirmingFreeOrder: false,
      goToWeb: false,
    }
  },

  methods: {
    goBack() {
      this.$refs.orderDialogRef.confirmAbandon()
    },
    goBackWeb() {
      this.goToWeb = true
      this.$refs.orderDialogRef.confirmAbandon()
    },
    displayConditionsDialog() {
      this.$refs.conditionsDialog.showModal = true
    },
    confirmOrder: function (e) {
      e.preventDefault()
      this.isConfirmingOrder = true
      this.$api
        .confirmMultipassOrder(this.order.lookup_ref, this.formData)
        .then((response) => {
          this.$refs.paymentForm.submit(response)
        })
        .catch((error) => {
          this.isConfirmingOrder = false
          if (error.response.status == 400) {
            this.errors = error.response.data.details
            this.$store.dispatch(
              "ui/error",
              this.$gettext("El formulario contiene errores")
            )
          }
        })
    },
    conditionsDialogAbandon() {
      this.$refs.orderDialogRef.confirmAbandon()
    },
    agreeConditions() {
      // Set conditions checkbox as given and send that value
      this.$refs.contactDataForm.orderSpecificConditionsStatus = "given"
    },
  },
  watch: {
    "order.status"(value) {
      if (value == "finished") {
        this.$router.push({ name: "order-success", query: this.$defaultQuery() })
      } else if (value == "abandoned") {
        this.$router.push({ name: "order-expired", query: this.$defaultQuery() })
      } else if (value == "canceled") {
        this.$router.push("/404")
      }
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.annotation
  background: rgba($info, .2)
  color: darken($info, 20%)
  font-weight: 500
  text-align: center
  margin: 1rem auto 1.5rem auto
  @include from($tablet)
    padding: 1.5rem .75rem
  @include until($tablet)
    padding: .75rem

.event-card
  flex-grow: 1

.payment-info
  background: $grey-lighter
  margin: 1.5rem auto

.order-confirm-grid
  margin-top: 2rem
  @include until($widescreen)
    .contact-data-form
      margin-top: 1.5rem
  @include from($widescreen)
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    & > *
      width: 50%

    .contact-data-form
      order: 0
      width: 100%
    .order-summary
      width: 100%
      order: 1
      font-size: .9rem
    .payment-info
      order: 3
    .order-confirm-actions
      order: 4
</style>
